<template>
    <div class="box">
        <Header type="expert" :navList="navList" @search="handleSearchEvent" :typeList="[]" :navCurrentId="navCurrentId" :logo="logoUrl"></Header>
        <div class="flex-item">
            <div class="container">
                <!-- 面包屑 -->
                <div class="flex pb5 x-left">
                    <div class="color999">您所在的位置：</div>
                    <a-breadcrumb>
                        <a-breadcrumb-item>
                            <router-link class="color999" :to="{path:'/'}">首页</router-link>
                        </a-breadcrumb-item>
                        <a-breadcrumb-item>
                            <router-link class="color999" :to="{path:'/expert/index'}">医咖秀</router-link>
                        </a-breadcrumb-item>
                        <a-breadcrumb-item>学科主委</a-breadcrumb-item>
                    </a-breadcrumb>
                </div>
                <filter-tab v-if="tabList.length" title="学科" :list="tabList" :isShowMore="true" @clickItem="handleTapSubject"></filter-tab>
                <!--    学科主委   -->
                <div class="charming-style mt40">
                    <a-spin :spinning="loading" tip="Loading...">
                        <div v-if="academicianList.length" class="list-box">
                            <expert-list :dataList="academicianList" v-show="!loading"></expert-list>
                        </div>
                        <a-empty v-if="!academicianList.length && !loading" />
                    </a-spin>
                    <div class="t-c mt20">
                        <a-pagination v-model="paginationParams.page" :hideOnSinglePage="true" show-quick-jumper show-size-changer :pageSizeOptions="pageSizeOptions" :pageSize="paginationParams.pageSize" :total="paginationParams.total" @change="getList" @showSizeChange="handleChangeSize" />
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import { expertNavList, exportLogo } from "@/config/const";
import Header from '@/components/layout/layout-header';
import Footer from '@/components/layout/layout-footer';
import expertList from '@/components/expert-list';
import filterTab from '@/components/filter-tab.vue';
import { jsonFormat } from "@/utils/jsonFormat";
export default {
    name: "ExpertCommittee",
    components: {
        Header, Footer, expertList, filterTab
    },
    data() {
        return {
            logoUrl: exportLogo,
            navList: expertNavList,//顶部菜单
            navCurrentId: 4,//当前页面菜单对应下标
            academicianList: [],
            tabList: [],
            loading: true,
            pageSizeOptions: ['12', '16', '20', '24'],
            paginationParams: {
                page: 1, //页数
                pageSize: 12, //当前页展示多少
                total: 0, // 数据总量
            },
        }
    },
    created() {
        this.__search_name = ''
        this.getList();
        this.getTabList();
    },
    // 缓存页面二次进入的钩子函数
    // activated() {
    //     this.getList();
    // },
    methods: {
        handleSearchEvent(search_name){
            this.paginationParams.page = 1
            this.__search_name = search_name
            this.getList()
        },
        getList() {
            let { page, pageSize } = this.paginationParams;
            this.loading = true;
            let data = { committee_id: 50, limit: pageSize, page }
            this.__search_name && (data['name'] = this.__search_name);

            this.request.post('AllCurrentChairman', data).then(res => {
                let formatTemplate = {
                    name: 'real_name',
                    subject: 'department',
                    company: 'org_cnname',
                    avatar: 'avatar',
                    introduce: 'resume',
                    user_id: 'UserID'
                }
                let data = res.data.list;
                this.academicianList = jsonFormat(data, formatTemplate);
                this.paginationParams.total = parseInt(res.data.count);
                this.loading = false;
            })
        },
        handleChangeSize(current, size) {
            console.log(current, size);
            this.paginationParams.pageSize = size;
            this.getList();
        },
        getTabList() {
            this.request.post('CmaAbbreviation').then(res => {
                res.data.forEach(item => {
                    let name = item.name;
                    item.name = item.simple_name;
                    item.simple_name = name;
                })
                this.tabList = res.data;
            })
        },
        handleTapSubject(index) {
            let data = this.tabList[index];
            if (index != -1) {
                let { name, id, simple_name } = data;
                this.$router.push({
                    path: '/expert/subject-search',
                    query: {
                        name, id, simple_name
                    }
                })
            }
        },
    }
}
</script>

<style scoped lang="less">
.list-box {
    min-height: 500px;
}
@media screen and (max-width:768px){
    .container{
        padding: 10px;
        /deep/.ant-pagination-item{
            min-width: 0;
        }
    }
    /deep/.unlogin-header,/deep/.login-header{
        right: 30px!important;
    }
}
</style>